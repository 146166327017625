

.bottomNavActions {
    position: fixed;
    width: 100%;
    bottom: 0;
}


.HealthRecommendationComponentChangeButtonGrid {
}
